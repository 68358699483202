import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import { colors } from "../lib/theme";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MemoryIcon from "@mui/icons-material/Memory";
import { Helmet } from "react-helmet";

const Welcome = () => (
  <Container maxWidth="sm">
    <Helmet>
      <title>Welcome to Golden Record</title>
      <meta name="description" content="Golden Record is a platform that allows you to record and create audio gifts to preserve the memories of your loved ones." />
    </Helmet>
    <Box sx={{ mb: 8 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 12,
          background: `linear-gradient(45deg, ${colors.gold} 30%, ${colors.white} 90%)`,
          borderRadius: 16,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        }}
      >
        <Stack spacing={3} alignItems="center">
          <PhoneIphoneIcon sx={{ fontSize: 60, color: colors.darkGold }} />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ color: colors.darkGold, fontWeight: "bold" }}
          >
            Your Account is Ready!
          </Typography>
          <Typography variant="h6" align="center" sx={{ color: colors.darkGold }}>
            Time to start collecting audio recorded memories
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<MemoryIcon />}
            href="https://apps.apple.com/us/app/golden-record/id6501951254"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              bgcolor: '#8B6B00',
              color: colors.white,
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1.1rem',
              padding: '12px 24px',
              letterSpacing: '1px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                bgcolor: '#6B5200',
                color: colors.white,
                boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Install the app
          </Button>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: colors.darkGold, mt: 2 }}
          >
            Start preserving your precious memories today!
          </Typography>
        </Stack>
      </Paper>
    </Box>
  </Container>
);

export default Welcome;
